<script setup lang="ts">
import type { Game } from "@/types";

const props = defineProps<{
	game: Game & { skeleton?: boolean };
	type?: "slider_vertical" | "slider_horizontal" | "top10-fwl";
	indexGame?: number;
}>();

const {
	public: { baseImageUrl }
} = useRuntimeConfig();
const { t } = useT();
const loginGuard = useLoginGuard();
const { isMobileClient } = useWindowClient();
const { open } = useAppModals();
const { handleOpenGame } = useOpenGame(open);
const { handleToggleToFavoriteClick } = useAddGameToFavorite({ t, toastTheme: "dark", toastPosition: "bottom-center" });

const defaultBg = "/nuxt-img/card-games/default-bg.png";
const isGameFavorite = computed(() => props.game.isFavorite);

const handleToggleToFavorite = () => {
	handleToggleToFavoriteClick({ ...props.game, isFavorite: isGameFavorite.value });
};

const handleOpenGameClick = () => {
	loginGuard({
		success() {
			handleOpenGame(props.game.slug);
		},
		fail() {
			open("LazyOModalSignup");
		}
	});
};
</script>

<template>
	<div>
		<div v-if="type === 'top10-fwl'" class="game-image-wrapper">
			<MCardTopTen :indexGame="indexGame">
				<template #game-card>
					<MGameCard
						:game="game"
						:gameImg="`${baseImageUrl}${props.game?.logo}`"
						:gameImgIncrease="2"
						:defaultBg="defaultBg"
						:width="isMobileClient ? 150 : 172"
						:height="isMobileClient ? 168 : 194"
						:isFavorite="isGameFavorite"
						@toggle-favorite="handleToggleToFavorite"
						@click.prevent="handleOpenGameClick"
					>
						<template #top>
							<MGameBadges :game="game" />
						</template>
					</MGameCard>
				</template>
			</MCardTopTen>
		</div>

		<MGameCardHorizontal
			v-else-if="type === 'slider_horizontal'"
			:game="game"
			:gameImgHorizontal="`${baseImageUrl}${props.game?.logo2x1 || props.game?.logo}`"
			:gameImg="`${baseImageUrl}${props.game?.logo}`"
			:gameImgIncrease="2"
			:defaultBg="defaultBg"
			:width="284"
			:height="174"
			:buttonName="t('Play')"
			:isFavorite="isGameFavorite"
			@toggle-favorite="handleToggleToFavorite"
			@click.prevent="handleOpenGameClick"
		>
			<template #top>
				<MGameBadges :game="game" />
			</template>
		</MGameCardHorizontal>
		<MGameCard
			v-else-if="type === 'slider_vertical'"
			:game="game"
			:gameImg="`${baseImageUrl}${props.game?.logo1x2 || props.game?.logo}`"
			:gameImgIncrease="2"
			:defaultBg="defaultBg"
			:width="isMobileClient ? 124 : 172"
			:height="isMobileClient ? 214 : 277"
			:isFavorite="isGameFavorite"
			@toggle-favorite="handleToggleToFavorite"
			@click.prevent="handleOpenGameClick"
		>
			<template #top>
				<MGameBadges :game="game" />
			</template>
		</MGameCard>
		<MGameCard
			v-else-if="type === 'slider_horizontal_small'"
			:game="game"
			:gameImg="`${baseImageUrl}${props.game?.logo}`"
			:gameImgIncrease="2"
			:defaultBg="defaultBg"
			:width="isMobileClient ? 108 : 110"
			:height="isMobileClient ? 121 : 123"
			:isFavorite="isGameFavorite"
			@toggle-favorite="handleToggleToFavorite"
			@click.prevent="handleOpenGameClick"
		>
			<template #top>
				<MGameBadges :game="game" />
			</template>
		</MGameCard>
		<MGameCard
			v-else
			:game="game"
			:gameImg="`${baseImageUrl}${props.game?.logo}`"
			:gameImgIncrease="2"
			:defaultBg="defaultBg"
			:width="isMobileClient ? 124 : 172"
			:height="isMobileClient ? 140 : 194"
			:isFavorite="isGameFavorite"
			@toggle-favorite="handleToggleToFavorite"
			@click.prevent="handleOpenGameClick"
		>
			<template #top>
				<MGameBadges :game="game" />
			</template>
		</MGameCard>
	</div>
</template>

<style scoped lang="scss">
.game-card-horizontal {
	background: var(--gray-900);
	border-radius: 8px;
}
:deep(.game-card) {
	.image-block {
		border-radius: 8px;
		background: var(--neutral-900);
	}
	.game-overlay {
		background-color: rgba(var(--gray-950-rgb), 0.8);
		.nuxt-icon {
			font-size: 50px;
		}
	}
	.top-info {
		z-index: 3;
	}
	.btn-favorite {
		top: 8px;
		left: auto;
		right: 8px;
		padding: 2px;
		background: var(--gray-900);
		border: 1px solid var(--gray-800);
		border-radius: 8px;
		width: 30px;
		height: 30px;
		&.active {
			background: var(--gray-700);
			border: px solid var(--gray-600);
		}
	}
}
</style>
